import { useRef } from 'react';
import plusLight from '../../assets/chatbot/plus_light.svg';
import plusDark from '../../assets/chatbot/plus_dark.svg';
import sendDark from '../../assets/chatbot/send_dark.svg';
import sendLight from '../../assets/chatbot/send_light.svg';
import sendWhiteFill from '../../assets/chatbot/send_white_fill.svg';
import Home from './Home';
import { DKSpinner } from 'deskera-ui-library'

export default function ChatInput({ setSuggestion, chatLog, darkMode, handleSubmit, input, setInput, loading }) {
  
  const textareaRef = useRef(null);
  const handleTextareaKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey && input.trim().length > 0) {
      e.preventDefault();
      // Submit the form when Enter is pressed without Shift
      handleSubmit(e.target.value);
    } else if (e.key === 'Enter' && e.shiftKey) {
      // Insert a new line character in the textarea
      const currentPosition = textareaRef.current.selectionStart;
      const currentValue = textareaRef.current.value;
      const newValue =
        currentValue.substring(0, currentPosition) +
        '\n' +
        currentValue.substring(currentPosition);
      textareaRef.current.value = newValue;
      // Prevent the default Enter behavior (new line)
      e.preventDefault();
    }
  };

  const handleClick = () => {
      !loading && input.trim().length>0 && handleSubmit(input);
  }
  return (
    <div className='chat-input-container'>
      {chatLog && chatLog.length<1 && <Home loading={loading} darkMode={darkMode} setSuggestion={setSuggestion} handleSubmit={handleSubmit} />}
      <div className={`chat-input-sub-container${darkMode ? '-dark' : ''} d-flex row justify-content-between`}>
        <div className={`chat-input-form-container${darkMode ? '-dark' : ''} d-flex w-100 align-items-center`}>
          {/* <div className='mr-s cursor-pointer'><img src={darkMode ? plusDark : plusLight} alt="Add" /></div> */}
          <form onSubmit={handleClick} className='w-100'>
            <textarea 
              id='chat-input'
              rows='1'
              type='text'
              value={input}
              onChange={(e) => setInput(e.target.value)}
              placeholder='Type message'
              // className='p-l border-m border-radius-m'
              className={`input ${loading && 'cursor-not-allowed'} custom-scroll`}
              style={{ cursor: loading && 'not-allowed' }}
              disabled={loading}
              autoComplete='off'
              ref={textareaRef} // Use a ref to access the textarea element
              onKeyDown={handleTextareaKeyDown} // Handle keydown event
            />
          </form>
        </div>
          <div className={`border-radius-s chat-input-send-btn${darkMode ? 'dark' : ''} ml-m`} onClick={() => handleClick()} style={{ cursor: loading ? 'not-allowed' : 'pointer' }}>
            {
              loading
              ?
              (darkMode ? <DKSpinner className="mr-l" iconClassName="ic-s-2" isWhite/> : <DKSpinner className="mr-l" iconClassName="ic-s-2"/>)
              :
              (<img className={`chat-input-send-icon${input.trim().length===0 ? '' : '-green'}${darkMode ? '-dark' : ''}`} src={darkMode ? (input.trim().length===0 ? sendDark : sendWhiteFill) : (input.trim().length===0 ? sendLight : sendWhiteFill)} alt="Send" />)
            }
          </div>
      </div>
    </div>
  )
}
