import React, { useEffect, useState } from 'react'

function Home({ loading, darkMode, setSuggestion, handleSubmit }) {

  const [randomSuggestions, setRandomSuggestions] = useState([]);

  useEffect(() => {
    // Function to randomly select n elements from an array
    function getRandomElementsFromArray(arr, n) {
      const shuffled = arr.sort(() => 0.5 - Math.random());
      return shuffled.slice(0, n);
    }

    const random = getRandomElementsFromArray(homeSuggestions, 4);
      setRandomSuggestions(random);
    }, [])
    
  const homeSuggestions = [
    {"questionUserCanAsk": "Break down this month's P&L?", "description": "Detailed monthly P&L"},
    {"questionUserCanAsk": "Last quarter's cash flow trend?", "description": "Quarterly Cash Flow"},
    {"questionUserCanAsk": "Year-over-year P&L?", "description": "Annual P&L comparison"},
    {"questionUserCanAsk": "Quarterly bank reconciliation?", "description": "Quarterly Reconciliation"},
    {"questionUserCanAsk": "This year's balance sheet by quarter?", "description": "Quarterly Balance Sheet"},
    {"questionUserCanAsk": "Status of vendor payables?", "description": "Vendor Accounts"},
    {"questionUserCanAsk": "Fiscal year Trial Balance?", "description": "Annual Trial Balance"},
    {"questionUserCanAsk": "90-day aged receivables?", "description": "Aged Receivables"},
    {"questionUserCanAsk": "Sales segmented by product?", "description": "Sales By Product"}
  ]
  
  return (
      <div className={`home-container${darkMode ? '-dark' : ''}`}>
        <div className={`home-heading-container${darkMode ? '-dark' : ''}  p-s`}>
          <div className={`home-heading${darkMode ? '-dark' : ''} `}>
            <div className={`home-heading-item${darkMode ? '-dark' : ''}`}>Deskera AI</div>
          </div>
        </div>
        <div className="home-suggestion-row-container">
          <div className="home-suggestion-row">
            <div
              className={`home-suggestion-item${
                darkMode ? "-dark" : ""
              } cursor-pointer`}
              onClick={() => {
                setSuggestion((prevState) => ({
                  ...prevState,
                  selected: true,
                  value: randomSuggestions[0].questionUserCanAsk,
                }))
                handleSubmit(randomSuggestions[0].questionUserCanAsk)
              }}
            >
              <span className={`random-suggestion-title${darkMode ? "-dark" : ""}`}>
                {randomSuggestions[0] && randomSuggestions[0].description}
              </span>
              <span className={`random-suggestion-question${darkMode ? "-dark" : ""}`}>
                {randomSuggestions[0] && randomSuggestions[0].questionUserCanAsk}
              </span>
            </div>
            <div
              className={`home-suggestion-item${
                darkMode ? "-dark" : ""
              } cursor-pointer`}
              onClick={() => {
                setSuggestion((prevState) => ({
                  ...prevState,
                  selected: true,
                  value: randomSuggestions[1].questionUserCanAsk,
                }))
                handleSubmit(randomSuggestions[1].questionUserCanAsk)
              }}
            >
              <span className={`random-suggestion-title${darkMode ? "-dark" : ""}`}>
                {randomSuggestions[1] && randomSuggestions[1].description}
              </span>
              <span className={`random-suggestion-question${darkMode ? "-dark" : ""}`}>
                {randomSuggestions[1] && randomSuggestions[1].questionUserCanAsk}
              </span>
            </div>
          </div>
          <div className="home-suggestion-row">
            <div
              className={`home-suggestion-item${
                darkMode ? "-dark" : ""
              } cursor-pointer`}
              onClick={() => {
                setSuggestion((prevState) => ({
                  ...prevState,
                  selected: true,
                  value: randomSuggestions[2].questionUserCanAsk,
                }))
                handleSubmit(randomSuggestions[2].questionUserCanAsk)
              }}
            >
              <span className={`random-suggestion-title${darkMode ? "-dark" : ""}`}>
                {randomSuggestions[2] && randomSuggestions[2].description}
              </span>
              <span className={`random-suggestion-question${darkMode ? "-dark" : ""}`}>
                {randomSuggestions[2] && randomSuggestions[2].questionUserCanAsk}
              </span>
            </div>
            <div
              className={`home-suggestion-item${
                darkMode ? "-dark" : ""
              } cursor-pointer`}
              onClick={() => {
                setSuggestion((prevState) => ({
                  ...prevState,
                  selected: true,
                  value: randomSuggestions[3].questionUserCanAsk,
                }))
                handleSubmit(randomSuggestions[3].questionUserCanAsk)
              }}
            >
              <span className={`random-suggestion-title${darkMode ? "-dark" : ""}`}>
                {randomSuggestions[3] && randomSuggestions[3].description}
              </span>
              <span className={`random-suggestion-question${darkMode ? "-dark" : ""}`}>
                {randomSuggestions[3] && randomSuggestions[3].questionUserCanAsk}
              </span>
            </div>
          </div>
        </div>
    </div>
  )
}

export default Home
