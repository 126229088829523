import React, { useState, useRef, useEffect, Component } from 'react';
import axios from 'axios';
import { DKSpinner, DKIcon, DKInput, DKIcons, DKSearchBar } from 'deskera-ui-library';
import ic_profile_pic from "../../assets/menu/ic_profile_pic.png";
import Product from "./import_data_from_other_platforms/QuickBooks/Product";
import ChartOfAccounts from "./import_data_from_other_platforms/QuickBooks/ChartOfAccounts";
import Contacts from "./import_data_from_other_platforms/QuickBooks/Contacts";
import Invoices from './import_data_from_other_platforms/QuickBooks/Invoices';
import Tenant from '../../services/Tenant';
// import UserManager from '../../managers/UserManager';
// import TenantManager from '../../managers/TenantManager';
import { TENANT, TenantService } from "../../services/TenantMangerService";
import Table from '../../services/Table';
import ApiConstants from '../../utils/ApiConstants';
import { v4 as uuidv4 } from 'uuid';
import MarkdownIt from 'markdown-it';
import io, { Socket } from 'socket.io-client';
import SpinnerText from '../common/SpinnerText';
import ChatHeader from './ChatHeader';
import ChatConversation from './ChatConversation';
import ChatInput from './ChatInput';
import ChatSidebar from './ChatSidebar';
import ChatSuggestions from './ChatSuggestions';
import renderGraph from '../../utils/GraphUtils';
import { getAllThreads, addNewMessage, createNewThread, getAnswer, updateThreadName, sendMessageAndGetReply, createThread, getThreadList, getMessageList } from '../../utils/ChatUtils';
import { useSocket } from '../../hooks/useSocket';
import { useTenantData } from '../../hooks/useTenantData';

const componentMap = {
    'import_data_from_other_platforms/quickbooks/product': <Product />,
    'import_data_from_other_platforms/quickbooks/chart of accounts': <ChartOfAccounts />,
    'import_data_from_other_platforms/quickbooks/contacts': <Contacts />,
    'import_data_from_other_platforms/quickbooks/invoices': <Invoices />,
}

export default function ChatBody({ loading, setLoading, chatLog, setChatLog, threads, setThreads, pageWidth, setPageWidth, sidebarIsActive, setSidebarIsActive, suggestion, setSuggestion, selectedThread, setSelectedThread, darkMode, toggleDarkMode }) {

    const [receivedData, setReceivedData] = useState("");
    const [finalData, setFinalData] = useState("");
  
    const [input, setInput] = useState("");

    const [chatRoom, setChatRoom] = useState();

    const tenantId = useTenantData();
  
    // const socket = useSocket(setChatLog, chatRoom);
    // useEffect(() => {
    //   if (socket) {
    //     setChatRoom(uuidv4());
    //   }
    // }, [socket]);

    const chatLogRef = useRef(null);

    // []
    useEffect(() => {
        const updatePageWidth = () => {
          const newWidth = window.innerWidth;
          setPageWidth(newWidth);
          setSidebarIsActive(newWidth >= 1300);
        };
      
        window.addEventListener('resize', updatePageWidth);
        updatePageWidth(); // Call once to set initial values
      
        return () => window.removeEventListener('resize', updatePageWidth);
    }, []);

    // tenantId
    useEffect(() => {
        if(tenantId) {
            async function handleGetThreadList() {
                const threadList = await getThreadList();
                setThreads(threadList);
            } 
            handleGetThreadList();
        }
    }, [tenantId])

    // // [chatLog]
    useEffect(() => {
        // auto-scroll to bottom
        if (chatLogRef.current) {
            chatLogRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [chatLog])

    // [loading]
    useEffect(() => {
        if (!loading) {
            // Automatically focus on the input element after assistant's response
            document.getElementById('chat-input').focus();
        }
    }, [loading]);
    
    // async function handleNewMessage(threadId, message, sender) {
    //   try {
    //       const res = await addNewMessage(threadId, message, sender);
    //       if (res !== null) {
    //           let updatedChatLog;
    //           if (sender === 'user') {
    //               updatedChatLog = [...chatLog, res];
    //               setChatLog(updatedChatLog);
    //               // Get bot response
    //             //   getAnswer(tenantId, ApiConstants, message, updatedChatLog, setSuggestion, setLoading, componentMap, setChatLog, handleNewMessage, chatRoom, threadId);
    //           } else {
    //               setChatLog((prevChatLog) => [...prevChatLog.slice(0, -1), res]);
    //           }
    //           return res;
    //       } else {
    //           console.error('Cannot retrieve messages');
    //           return null;
    //       }
    //   } catch (error) {
    //       console.error('An error occurred while fetching messages:', error);
    //       return null;
    //   }
    // }
    
    async function handleSubmit(userPrompt) {
        setInput("");
        setSuggestion({selected: false, value: '', options: []});
        setLoading(true);

        let threadId = selectedThread?._id;
        setChatLog((prevChatLog) => [
            ...prevChatLog, 
            {
              thread_id: threadId,
              content: {
                text_content: userPrompt,
              },
              sender: "user",
            },
            {
                thread_id: threadId,
                content: {
                    text_content: 'Loading...'
                },
                sender: "bot",
            }
        ]);

        if (!threadId) {
            try {
                const newThread = await createThread(userPrompt);
                threadId = newThread?._id;
                const newThreadWithDate = {...newThread, created_at: new Date().toISOString()}
                setThreads((prevThreads) => [newThreadWithDate, ...prevThreads]);
                setSelectedThread(newThread);
            } catch (error) {
                console.error('Error creating new thread:', error);
                return;
            }
        }
        try {
            const res = await sendMessageAndGetReply(threadId, userPrompt);
            res.isReply = true
            setChatLog((prevChatLog) => [...prevChatLog.slice(0, -1), res]);
        } catch (error) {
            console.error('Error sending message and getting reply:', error);
        } finally {
            setLoading(false);
        }
        
        // if(!threadId) {
        //     // create new thread
        //     try {
        //         const res = await createNewThread(tenantId);
        //         if (res) {
        //             threadId = res.thread.threadID;
        //             setThreads((prevState) => ([[threadId, res.thread.threadName], ...prevState]));
        //             setSelectedThread({id: threadId, name: res.thread.threadName})
        //             updateThreadName(threadId, userPrompt, setThreads, setSelectedThread);
        //         } else {
        //             console.error('Error! Cannot create new thread');
        //             setLoading(false);
        //             return;
        //         }
        //     } catch (error) {
        //         console.error('An error occurred while creating new thread:', error);
        //         setLoading(false);
        //         return;
        //     }
        // }
        // try {
        //     await handleNewMessage(threadId, userPrompt, 'user');
        // } catch (error) {
        //     console.error('An error occurred while adding new message:', error);
        //     setLoading(false);
        //     return;
        // }
    }

    return (
        <div className={`main-container${darkMode ? '-dark' : ''} ${!sidebarIsActive && 'flex-1'}`} style={{fontFamily: 'Inter'}}>
            {/* <ChatSidebar setSuggestion={setSuggestion} loading={loading} setSelectedThreadName={setSelectedThreadName} selectedThreadId={selectedThreadId} setSelectedThreadId={setSelectedThreadId} setChatLog={setChatLog} threads={threads} darkMode={darkMode} pageWidth={pageWidth} sidebarIsActive={sidebarIsActive} setSidebarIsActive={setSidebarIsActive} /> */}
            <ChatHeader threads={threads} selectedThread={selectedThread} sidebarIsActive={sidebarIsActive} darkMode={darkMode} toggleDarkMode={toggleDarkMode} setSidebarIsActive={setSidebarIsActive} chatLog={chatLog} />
            <ChatConversation 
                threads={threads}
                selectedThread={selectedThread}
                chatLog={chatLog} 
                chatLogRef={chatLogRef}
                componentMap={componentMap}
                setInput={setInput}
                handleSubmit={handleSubmit}
                suggestion={suggestion}
                setSuggestion={setSuggestion}
                darkMode={darkMode}
            />
            <ChatInput 
                handleSubmit={handleSubmit} 
                input={input} 
                loading={loading}
                setInput={setInput}
                darkMode={darkMode}
                chatLog={chatLog}
                setSuggestion={setSuggestion}
            />
        </div>
    )
}


