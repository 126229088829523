import ChatMessage from './ChatMessage';
import ChatSuggestions from './ChatSuggestions';

export default function ChatConversation({ threads, selectedThread, suggestion, chatLog, chatLogRef, componentMap, setSuggestion, darkMode, handleSubmit }) {
  return (
    <div className={`chat-container${darkMode ? '-dark' : ''} border-black custom-scroll`}>
      {chatLog && chatLog.map((message, index) => {
            return <ChatMessage 
              message={message} 
              size={chatLog.length} 
              index={index} 
              componentMap={componentMap}
              darkMode={darkMode} 
            />
        })}
        <div ref={chatLogRef} />
        <ChatSuggestions threads={threads} selectedThread={selectedThread} chatLog={chatLog} suggestion={suggestion} setSuggestion={setSuggestion} darkMode={darkMode} handleSubmit={handleSubmit} />
    </div>
  )
}
