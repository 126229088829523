import React, { useState, useEffect } from 'react';
export const FinalResultsLoader = ({ onComplete, label = "Preparing final results", AnimatedTextComponent }) => {
    const [isComplete, setIsComplete] = useState(false);
  
    useEffect(() => {
      const timer = setTimeout(() => {
        setIsComplete(true);
        onComplete();
      }, 2000);
  
      return () => clearTimeout(timer);
    }, [onComplete]);
  
    if (isComplete) return null;
  
    return (
      <div className="timeline-loader">
        <div className="timeline-step active">
          <div className="step-indicator">
            <div className="loading-spinner"></div>
          </div>
          <div className="step-label">
            <AnimatedTextComponent text={label} />
          </div>
        </div>
      </div>
    );
  };