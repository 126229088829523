import React from 'react';
import { DKIcon, DKIcons } from "deskera-ui-library";
import UserManager from '../../managers/UserManager';
import logoutDark from '../../assets/chatbot/logout_dark.svg';
import logoutLight from '../../assets/chatbot/logout_light.svg';
import AppManager from '../../managers/AppManager';


export default function UserInfoBtn({ darkMode }) {
  return (
    <button className={`user-info-btn${darkMode ? '-dark' : ''} d-flex row border-radius-xxl mt-m cursor-pointer`}>
      {darkMode ? <DKIcon src={DKIcons.white.ic_user} className="ic-r" /> : <DKIcon src={DKIcons.ic_user} className="ic-r" />}
      <span className='user-name fw-m ml-m'>{UserManager.getUserName()}</span>
      <img src={darkMode ? logoutDark : logoutLight} alt='' onClick={() => {AppManager.logout();}} className={`sign-out-btn${darkMode ? '-dark' : ''} cursor-pointer`}/>
    </button>
  )
}
