import axios from 'axios';
import ApiConstants from './ApiConstants';

export const axiosInstance = axios.create({
  withCredentials: true,
  baseURL: ApiConstants.URL.CHAT.BASE_CHAT,
});



// POST:: create new thread
export function createThread(userPrompt) {
  try {
    return axiosInstance.post(`/thread`, 
      {
        "first_message": userPrompt,
      },
      {
      headers: {
        'Accept': '*/*',
        'x-access-token': ApiConstants.ACCESS_TOKEN
      }
    })
    .then(response => {
      if(response.status === 200 || response.status === 201) {
        return response.data;
      }
    })
    .catch(error => {
        console.error('Error:', error);
    })
  } catch(err) {
      console.error('error fetching threads:', err);
  }
}

// GET:: get thread list
export function getThreadList() {
  try {
    return axiosInstance.get(`/thread`, {
      headers: {
        'Accept': '*/*',
        'x-access-token': ApiConstants.ACCESS_TOKEN
      }
    })
    .then(response => {
      if (response.status === 200) {
        return response.data.data;
      }
    })
    .catch(error => {
        console.error('Error:', error);
    })
  } catch(error) {
      console.error('error fetching threads:', error);
  }
}

// GET:: get message list
export function getMessageList(thread_id) {
  try {
    return axiosInstance.get(`/thread/${thread_id}/message`,
      {
      headers: {
        'Accept': '*/*',
        'x-access-token': ApiConstants.ACCESS_TOKEN
      }
    })
    .then(response => {
      if(response.status === 200) {
        return response.data.data;
      }
    })
    .catch(error => {
        console.error('Error:', error);
    })
  } catch(error) {
      console.error('error fetching threads:', error);
  }
}

// POST:: send message and get reply
export function sendMessageAndGetReply(thread_id, userPrompt) {
  try {
    return axiosInstance.post(`/thread/${thread_id}/message`, 
      {
        "content": userPrompt,
        "sender": "user"
      },
      {
      headers: {
        'Accept': '*/*',
        'x-access-token': ApiConstants.ACCESS_TOKEN
      }
    })
    .then(response => {
      if(response.status === 200 || response.status === 400) {
        return response.data.reply_message;
      }
    })
    .catch(error => {
        console.error('Error:', error);
    })
  } catch(err) {
      console.error('error fetching threads:', err);
  }
}

// DELETE:: delete thread
export function deleteThread(thread_id) {
  try {
    return axiosInstance.delete(`/thread/${thread_id}`, 
      {
      headers: {
        'Accept': '*/*',
        'x-access-token': ApiConstants.ACCESS_TOKEN
      }
    })
    .then(response => {
      if(response.status === 200) {
        return response.data.reply_message;
      }
    })
    .catch(error => {
        console.error('Error:', error);
    })
  } catch(err) {
      console.error('error fetching threads:', err);
  }
}

// POST:: read csv
export function readCsv(url) {
  try {
    return axiosInstance.post(`/read-csv`, 
      {
        "url": url
      },
      {
      headers: {
        'Accept': '*/*',
        'x-access-token': ApiConstants.ACCESS_TOKEN
      }
    })
    .then(response => {
      if(response.status === 200) {
        return response.data;
      }
    })
    .catch(error => {
        console.error('Error:', error);
    })
  } catch(err) {
      console.error('error fetching threads:', err);
  }
}

// PUT:: rename thread
export function renameThread(updated_thread_name, thread_id) {
  try {
    return axiosInstance.put(`/thread/${thread_id}`,
      {
        'name': updated_thread_name,
      },
      {
      headers: {
        'Accept': '*/*',
        'x-access-token': ApiConstants.ACCESS_TOKEN
      }
    })
    .then(response => {
      if(response.status === 200) {
        return response;
      }
    })
    .catch(error => {
        console.error('Error:', error);
    })
  } catch(error) {
      console.error('error fetching threads:', error);
  }
}


export function searchThreadsByMessageContent(searchText) {
  try {
    return axiosInstance.post(`/thread/search-thread-by-message-content`, 
      {
        searchText: searchText
      },
      {
      headers: {
        'Accept': '*/*',
        'x-access-token': ApiConstants.ACCESS_TOKEN
      }
    })
    .then(response => {
      if (response.status === 200) {
        return response.data.data;
      }
    })
    .catch(error => {
      console.error('Error:', error);
    });
  } catch (error) {
    console.error('error searching threads:', error);
  }
}