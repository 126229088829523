import { useState, useEffect } from 'react';
import Tenant from '../services/Tenant';
import { TenantService } from "../services/TenantMangerService";
import Table from '../services/Table';

export function useTenantData() {
  const [tenantId, setTenantId] = useState(null);

  useEffect(() => {
    async function fetchTenantData() {
      try {
        Tenant.getTenantDetails().then((tenant) => {
            TenantService.getUserData().then((userData) => {
                Table.getTenantData().then((peopleTenant) => {
                    setTenantId(peopleTenant.tenant.tenantId);
                })
            })
        })
      } catch (error) {
        console.error('Error fetching tenant data:', error);
      }
    }

    fetchTenantData();
  }, []);

  return tenantId;
}