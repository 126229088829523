const DeleteThreadPopup = ({ darkMode, confirmDelete, setDeleteConfirmation }) => (
    <div 
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0,0,0,0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000,
        borderRadius: '0.95919rem 0.95919rem 1.5625rem 1.5625rem',
        padding: '5px',
      }}
    >
      <div 
        style={{
          backgroundColor: darkMode ? '#333' : '#fff',
          padding: '20px',
          borderRadius: '10px',
          maxWidth: '300px',
        }}
      >
        <p style={{ color: darkMode ? '#fff' : '#000' }}>Are you sure you want to delete this thread?</p>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
          <button 
            onClick={confirmDelete}
            style={{
              padding: '10px 20px',
              backgroundColor: '#ff4d4f',
              color: '#fff',
              border: 'none',
              borderRadius: '5px',
              cursor: 'pointer'
            }}
          >
            Delete
          </button>
          <button 
            onClick={() => setDeleteConfirmation({ isOpen: false, threadId: null })}
            style={{
              padding: '10px 20px',
              backgroundColor: '#d9d9d9',
              color: '#000',
              border: 'none',
              borderRadius: '5px',
              cursor: 'pointer'
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );

  export default DeleteThreadPopup