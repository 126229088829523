// import { useState, useEffect } from 'react';
// import { AreaChart } from "deskera-ui-library";

// export default function BarGraph({ data }) {

//   const [graphData, setGraphData] = useState({});

//   useEffect(() => {
//     console.log(graphData);
//   }, [graphData])

//   useEffect(() => {
//     const graphDataArr = data.map((item) => ({
//       title: item.title,
//       value: item.value
//     }))
//     setGraphData(graphDataArr);
//   }, [])

  
//   // const data_1=
//   const threshold = {
//     title: "Threshold",
//     value: 100,
//     color: 'gray',
//     bgColorAbove: 'green',
//     bgColorBelow: 'yellow',
//     opacity: 0.3
//   }
    
//   return (
//     <AreaChart data={[
//     {
//         color: 'rgb(28, 115, 232)',
//         title: 'Mon',
//         value: 2000
//     },
//     {
//         color: 'rgb(95, 190, 250)',
//         title: 'Tue',
//         value: 4000
//     },
//     {
//         color: 'rgb(140, 235, 240)',
//         title: 'Wed',
//         value: 6000
//     },
//     {
//         color: 'rgb(116, 97, 238)',
//         title: 'Thu',
//         value: 1600
//     },
//     {
//         color: 'rgb(26, 175, 93)',
//         title: 'Fri',
//         value: 1400
//     },
//     {
//         color: 'rgb(250, 200, 60)',
//         title: 'Sat',
//         value: 8000
//     },
//     {
//         color: 'rgb(240, 97, 113)',
//         title: 'Sun',
//         value: 9000
//     }
//   ]} thresholdData={threshold} height={170} showLegend={true} className="" />
//   )
// }

/**Older graph */
import React, { useState, useEffect } from 'react';
import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis } from "recharts"

export default function BarGraph({ data }) {

  const [graphData, setGraphData] = useState({});
  const [pageWidth, setPageWidth] = useState(window.innerWidth);

  useEffect(() => {
    const graphDataArr = data.map((item) => ({
      name: item.title,
      total: item.value
    }))
    setGraphData(graphDataArr);
  }, [data])

  // Update pageWidth when the window is resized
    const updatePageWidth = () => {
        setPageWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', updatePageWidth);
        return () => {
            window.removeEventListener('resize', updatePageWidth);
        };
    }, []);
  
  return (
    <small className='' style={{overflow: 'auto', padding: '6rem'}}>
      <small><h1>Bar Graph</h1></small>
      {/* <ResponsiveContainer width={pageWidth>=1300 ? 550 : 920} height={245}> */}
      <ResponsiveContainer width={'100%'} height={245}>
        <BarChart data={graphData}>
          <XAxis
            dataKey="name"
            stroke="#888888"
            fontSize={8}
            tickLine={true}
            interval={5}
          />
          <YAxis
            stroke="#888888"
            fontSize={12}
            tickLine={true}
            axisLine={true}
            tickFormatter={(value) => `$${value}`}
          />
          <Bar dataKey="total" fill="#B7CBFF" radius={[10, 10, 0, 0]} isAnimationActive={false}/>
        </BarChart>
      </ResponsiveContainer>
    </small>
  )
}


