import BarGraph from '../components/chatbot/graphs/BarGraph';

export default function renderGraph(data) {
  const graphType = data.type;
  const graphData = data.graph_vals;
  // console.log('graphType', graphType);
  switch(graphType) {
    case 'bar':
      return <BarGraph data={graphData} />
    default:
      break;
  }
}