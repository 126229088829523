import ApiManager from "./ApiManager";
import UserManager from "./UserManager";
import Table from "../services/Table";
import { TableManger } from "./TableManager";
import ApiConstants from "../utils/ApiConstants";
import http from "../http";
import { TenantService } from "../services/TenantMangerService";
export default class InitialApiManager {
  static init(onSuccess, onFail) {
    ApiManager.checkIfUserLoggedIn(
      () => {
        if (UserManager.isOrgSet() === false) {
          onFail({ errorMessage: "Org not set" });
        } else {
          this.callInitialApis(onSuccess);
        }
      },
      () => {
        onFail();
      }
    );
  }

  static getTableData() {
    return Table.getAllViews().then(
      (data) => {
        TableManger.populateViewMap(
          data.filter((data) => !data.default && data.columnsMetaData)
        ); //TODO need to change this
        //   PipelineManager.get((data) => {});
        TenantService.getTenantData();
        // TenantService.getTenantConfig();
        TenantService.getUserData();
        return Promise.resolve();
      },
      (err) => {}
    );
  }

  static getPeopleSubscription() {
    return http
      .get(
        ApiConstants.URL.BASE + ApiConstants.URL.SUBENGINE.SUBSCRIPTION_PEOPLE
      )
      .then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.resolve(null);
        }
      );
  }
  static getSubscription() {
    return http
      .get(ApiConstants.URL.BASE + ApiConstants.URL.SUBENGINE.PLAN)
      .then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.resolve(null);
        }
      );
  }

  static getTableDataInit() {
    return Table.getAllViews().then(
      (data) => {
        TableManger.populateViewMap(
          data.filter((data) => !data.default && data.columnsMetaData)
        ); //TODO need to change this
        //   PipelineManager.get((data) => {});
        TenantService.getTenantData();
        // TenantService.getTenantConfig();
        return Promise.resolve();
      },
      (err) => {}
    );
  }

  static SubscribeTrailPlan() {
    const query = "Trial=PEOPLE";
    return http
      .put(
        `${ApiConstants.URL.BASE}${ApiConstants.URL.SUBENGINE.START_TRAIL}?${query}`
      )
      .then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.resolve(null);
        }
      );
  }
}
