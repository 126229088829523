import React, { useState, useEffect } from 'react';
import './TimelineLoader.css';

const AnimatedText = ({ text, delay = 50 }) => {
  const [displayedText, setDisplayedText] = useState('');

  useEffect(() => {
    let i = 0;
    const intervalId = setInterval(() => {
      setDisplayedText(text.slice(0, i));
      i++;
      if (i > text.length) clearInterval(intervalId);
    }, delay);

    return () => clearInterval(intervalId);
  }, [text, delay]);

  return <span>{displayedText}</span>;
};

const TimelineLoader = ({ steps }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [completedSteps, setCompletedSteps] = useState([]);

  useEffect(() => {
    if (currentStep < steps.length) {
      const timer = setTimeout(() => {
        setCompletedSteps([...completedSteps, currentStep]);
        setCurrentStep(currentStep + 1);
      }, steps[currentStep].duration);

      return () => clearTimeout(timer);
    }
  }, [currentStep, steps, completedSteps]);

  return (
    <div className="timeline-container">
      <div className="timeline-line"></div>
      <div className="timeline-loader">
        {steps.map((step, index) => (
          <div 
            key={index} 
            className={`timeline-step ${index <= currentStep ? 'active' : ''}`}
            style={{ display: index <= currentStep ? 'flex' : 'none' }}
          >
            <div className="step-indicator">
              {completedSteps.includes(index) ? (
                <span className="tick">✓</span>
              ) : (
                index === currentStep ? (
                  <div className="loading-spinner"></div>
                ) : null
              )}
            </div>
            <div className="step-label">
              {index === currentStep ? (
                <AnimatedText text={step.label} />
              ) : (
                step.label
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TimelineLoader;