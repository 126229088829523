import React, { useEffect, useState } from 'react'
import SpreadsheetModal from './SpreadsheetModal';
import Papa from 'papaparse';
import * as XLSX from 'xlsx';
import { readCsv } from '../../utils/ChatUtils';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

const SpreadsheetPreview = ({ darkMode, spreadsheet }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [columnDefs, setColumnDefs] = useState([]);
  const [csvIsLoading, setCsvIsLoading] = useState(false);
  const [gridApi, setGridApi] = useState(null);

  const fetchAndParseFile = async () => {
      const response = await readCsv(spreadsheet);
      Papa.parse(response, {
        complete: (result) => {
          const parsedData = result.data || [];
          if (parsedData.length > 0) {
            const cols = parsedData[0].map(header => ({
              field: header,
              filter: 'agSetColumnFilter',
              // floatingFilter: true
            }));
            setColumnDefs(cols);
  
            const rows = parsedData.slice(1).map((row, index) => {
              const rowObj = { id: index };
              row.forEach((cell, cellIndex) => {
                rowObj[parsedData[0][cellIndex]] = cell;
              });
              return rowObj;
            });
            setRowData(rows);
            // setRowData([]);
            // setColumnDefs([]);
            setCsvIsLoading(false);
          }
        },
      });
  };

  const handleView = () => {
    setCsvIsLoading(true);
    setIsModalOpen(true);
    fetchAndParseFile();
  };

  const getFileNameAndExtension = (url) => {
    // Extract the file name from the URL
    const fullFileName = url?.split('/').pop();
    // Split the name and remove any hash
    const parts = fullFileName?.split('.');
    const extension = parts[parts.length - 1].toLowerCase();
    // Remove the hash and extension, then rejoin if there were dots in the original name
    const name = parts.slice(0, -1).join('.').replace(/\.[a-f0-9]{8}$/, '');
    
    return {
      name: name,
      extension: extension === 'csv' ? 'csv' : 'xlsx' // Default to xlsx if not csv
    };
  };

  const { name, extension } = getFileNameAndExtension(spreadsheet);
  const fileType = extension === 'csv' ? 'CSV' : 'Excel';

  const handleDownload = () => {
    const { name, extension } = getFileNameAndExtension(spreadsheet);
    const fileName = `${name}.${extension}`;

    const link = document.createElement('a');
    link.href = spreadsheet;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const onCsvExportClick = () => {
    if (gridApi) {
      const params = {
        skipHeader: false,
        skipFooters: true,
        skipGroups: true,
        fileName: `${name}.${extension}`,
        // To export only visible columns:
        // allColumns: false,
      };
      gridApi.exportDataAsCsv(params);
    }
  }

  return (
    <>
      <div className={`spreadsheet-preview${darkMode ? `-dark` : ``}`}>
        <div className="spreadsheet-icon">📊</div>
        <div className="spreadsheet-info">
          <div className="spreadsheet-name">{`${name}.${extension}`}</div>
          <div className="spreadsheet-type">{fileType} Spreadsheet</div>
        </div>
        {extension==='csv' && <button className="preview-button" onClick={handleView}>View</button>}
        <button className="preview-button" onClick={handleDownload}>Download</button>
      </div>
      <SpreadsheetModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <div className={`ag-theme-alpine${darkMode ? '-dark' : ''}`} style={{ position: 'relative', height: '100%', width: '100%' }}>
          
          <button className={`modal-close${darkMode ? '-dark' : ''}`} onClick={() => setIsModalOpen(false)}>×</button>
          <AgGridReact
            rowData={rowData}
            columnDefs={columnDefs}
            pagination={true}
            defaultColDef={{
              flex: 1,
              minWidth: 200,
              filter: true,
              sortable: true,
              resizable: true,
            }}
            overlayLoadingTemplate={'<span class="ag-overlay-loading-center">Loading...</span>'}
            overlayNoRowsTemplate={'<span class="ag-overlay-loading-center">Empty</span>'}
            onGridReady={(params) => {
              setGridApi(params.api);
              if (csvIsLoading) {
                params.api.showLoadingOverlay();
              } else if (rowData.length === 0) {
                params.api.showNoRowsOverlay();
              }
            }}
          />
          {
            !csvIsLoading &&
            <div style={{ display: 'flex', position: 'absolute', bottom: '12px', left: '10px', gap: '15px' }}>
              <button onClick={onCsvExportClick} style={{ background: 'transparent', border: '1px solid gray', padding: '5px', cursor: 'pointer' }}>Export as CSV</button>
          </div>
          }
        </div>
      </SpreadsheetModal>
    </>
  )
}

export default SpreadsheetPreview