import React, { useState, useRef, useEffect, Component } from "react";
import moment from 'moment';
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  DKSpinner,
  DKIcon,
  DKInput,
  DKIcons,
  DKSearchBar,
} from "deskera-ui-library";
import userAvatarLight from "../../assets/chatbot/user-avatar_light.svg";
import userAvatarDark from "../../assets/chatbot/user-avatar_dark.svg";
import aiAvatar from "../../assets/chatbot/ai-avatar.svg";
import MarkdownIt from "markdown-it";
import renderGraph from "../../utils/GraphUtils";
import { render } from "@testing-library/react";
import SpreadsheetPreview from "./SpreadsheetPreview";
import sample_spreadsheet from "../../assets/chatbot/example_csv_file.csv";
import TimelineLoader from "./TimelineLoader";
import { FinalResultsLoader } from "./FinalResultLoader";
import { AnimatedText } from "./AnimatedText";
import { format as sqlFormat } from 'sql-formatter';
import { axiosInstance } from "../../utils/ChatUtils";
import ApiConstants from "../../utils/ApiConstants";


export default function ChatMessage({ message, darkMode }) {
  let markdownMessage = null;

  function isHtml(str) {
    return /<[a-z][\s\S]*>/i.test(str);
  }
  const md = new MarkdownIt();

  function renderExtraData(data, level = 0) {
    if (data && typeof data === 'object' && !Array.isArray(data)) {
      return (
        <div className={`extra-data-section${darkMode ? "-dark" : ""}`} style={{ paddingLeft: `${level * 20}px` }}>
          {Object.keys(data).map((key) => (
            <div key={key} className={`extra-data-item${darkMode ? "-dark" : ""}`}>
              <details>
                <summary>{key}</summary>
                {renderExtraData(data[key], level + 1)}
              </details>
            </div>
          ))}
        </div>
      );
    } else if (Array.isArray(data)) {
      return (
        <ul className="extra-data-list" style={{ paddingLeft: `${level * 20}px` }}>
          {data.map((item, index) => (
            <li key={index} className="extra-data-list-item">
              {renderExtraData(item, level + 1)}
            </li>
          ))}
        </ul>
      );
    } else if (data !== null && data !== undefined) {
      return (
        <div
          className={`extra-data-value${darkMode ? "-dark" : ""}`}
          style={{ paddingLeft: `${level * 20}px` }}
          dangerouslySetInnerHTML={{ __html: md.render(String(data)) }}
        />
      );
    } else {
      return (
        <div
          className={`extra-data-value${darkMode ? "-dark" : ""}`}
          style={{ paddingLeft: `${level * 20}px` }}
        >
          null
        </div>
      );
    }
  }
  if (
    message &&
    message?.sender === "bot" &&
    typeof message?.content?.text_content === "string"
  ) {
    if (!isHtml(message?.content?.text_content)) {
      markdownMessage = new MarkdownIt().render(message?.content?.text_content);
    } else {
      markdownMessage = null;
    }
  }
  let renderedGraph = null;
  if (
    message &&
    message.message &&
    message.message.graphContent &&
    message.message.graphContent.graph_vals &&
    Array.isArray(message.message.graphContent.graph_vals)
  ) {
    renderedGraph = renderGraph({
      graph_vals: message.message.graphContent.graph_vals,
      type: message.message.graphContent.type,
    });
  }
  const [showFinalResults, setShowFinalResults] = useState(message.isReply);
  const formattedDate = moment(message.created_at).format('LLL');
  const [feedback, setFeedback] = useState(message.feedback?.helpful || null);

  const handleFeedback = async (helpful) => {
    try {
      const response = await axiosInstance.patch(`/thread/${message.thread_id}/message/${message._id}/feedback`, {
        feedback: {
          flag: helpful === 'yes' ? 'liked' : 'disliked',
          helpful: helpful
        }
      }, { headers: {
        'Accept': '*/*',
        'x-access-token': ApiConstants.ACCESS_TOKEN
      }} );

      if (response.status !== 200) {
        throw new Error('Network response was not ok');
      }

      setFeedback(helpful);
    } catch (error) {
      console.error('Error sending feedback:', error);
    }
  };

  return (
    <>
      {message?.sender === "user" ? (
        <div className={`user-message-container${darkMode ? "-dark" : ""}`}>
          <div className="user-avatar">
            <img
              src={darkMode ? userAvatarLight : userAvatarDark}
              alt="User Avatar"
            />
          </div>
          <div className="user-message ml-l w-80">
            <div
              className={`user-message-intro${
                darkMode ? "-dark" : ""
              } border-radius-l p-l`}
            >
              {message?.content.text_content}
            </div>
          </div>
        </div>
      ) : (
        <div className={`bot-message-container${darkMode ? "-dark" : ""}`}>
          <div className="bot-avatar">
            <img src={aiAvatar} alt="AI Avatar" />
          </div>
          <div className="bot-message ml-l w-80">
            <div
              className={`bot-message-intro${
                darkMode ? "-dark" : ""
              } border-radius-l p-l`}
            >
              {message.meta_data && message.meta_data.wasCacheUsed && (
                <div className="cache-chip">Cache Used</div>
              )}
              {message?.content.text_content?.startsWith("Loading") ? (
                <div className="timeline-container">
                  <div className="timeline-line"></div>
                  <TimelineLoader
                    steps={[
                      { label: "Processing request", duration: 2000 },
                      { label: "Thinking", duration: 6000 },
                      { label: "Determining which data to crawl", duration: 6000 },
                      { label: "Analyzing data patterns", duration: 8000 },
                      { label: "Identifying relevant datasets", duration: 8000 },
                      { label: "Extracting key information", duration: 10000 },
                      { label: "Validating data integrity", duration: 7000 },
                      { label: "Normalizing data formats", duration: 5000 },
                      { label: "Aggregating data points", duration: 9000 },
                      { label: "Cross-referencing sources", duration: 11000 },
                      { label: "Filtering results", duration: 20000 },
                      { label: "Compiling insights", duration: 12000 },
                      { label: "Optimizing output", duration: 16000 },
                      { label: "Generating report", duration: 120000 },
                      { label: "Verifying accuracy", duration: 15000 }
                    ]}
                    AnimatedTextComponent={AnimatedText}
                  />
                </div>
              ) : (
                <>
                  {message.isReply && showFinalResults && (
                    <FinalResultsLoader onComplete={() => setShowFinalResults(false)} AnimatedTextComponent={AnimatedText} />
                  )}
                  {(!message.isReply || !showFinalResults) && (
                    <>
                      {markdownMessage ? (
                        <div
                          className={`markdown-msg`}
                          dangerouslySetInnerHTML={{ __html: markdownMessage }}
                        />
                      ) : (
                        <div
                          className="markdown-msg"
                          dangerouslySetInnerHTML={{
                            __html: message?.content.text_content,
                          }}
                        />
                      )}
                      {message?.content?.attachments?.length > 0 && (
                        message.content.attachments.map((attachment, index) => (
                          <SpreadsheetPreview
                            key={index}
                            darkMode={darkMode}
                            spreadsheet={attachment.fileUrl}
                          />
                        ))
                      )}
                      {message?.content?.steps_text && (
                        <div className="steps-section">
                          <h4>Steps I took to find the answer</h4>
                          <div
                            className="markdown-msg"
                            dangerouslySetInnerHTML={{
                              __html: message.content.steps_text,
                            }}
                          />
                        </div>
                      )}
                      {message?.meta_data && (
                        <div className={`meta-data-section${darkMode ? "-dark" : ""}`}>
                          <h4>Meta Data</h4>
                          {message.meta_data.command && (
                            <div className="meta-data-item">
                              <strong>Command:</strong> {message.meta_data.command}
                            </div>
                          )}
                          {message.meta_data.genSqlQuery && (
                            <div className="meta-data-item">
                              <strong>Generated SQL Query:</strong> 
                              <pre className="code-block">
                                {message.meta_data.genSqlQuery}
                                <CopyToClipboard text={message.meta_data.genSqlQuery}>
                                  <button className="cache-chip">Copy</button>
                                </CopyToClipboard>
                              </pre>
                            </div>
                          )}
                          {message.meta_data.genSqlQueryOriginal && (
                            <div className="meta-data-item">
                              <strong>Original SQL Query:</strong> 
                              <pre className="code-block">
                                {message.meta_data.genSqlQueryOriginal && sqlFormat(message.meta_data.genSqlQueryOriginal, { language: 'postgresql' })}
                                <CopyToClipboard text={message.meta_data.genSqlQueryOriginal}>
                                  <button className="copy-button">Copy</button>
                                </CopyToClipboard>
                              </pre>
                            </div>
                          )}
                          {message.meta_data.modifiedSQLRes && (
                            <div className="meta-data-item">
                              <strong>Modified SQL Response:</strong> {message.meta_data.modifiedSQLRes}
                            </div>
                          )}
                          {message.meta_data.sqlQueries && message.meta_data.sqlQueries.length > 0 && (
                            <div className="meta-data-item">
                              <strong>SQL Queries:</strong>
                              <ul>
                                {message.meta_data.sqlQueries.map((queryObj, index) => (
                                  <li key={index}>
                                    <strong>Query:</strong> 
                                    <pre className="code-block">
                                      {queryObj.query && sqlFormat(queryObj.query, { language: 'postgresql' })}
                                      <CopyToClipboard text={queryObj.query}>
                                        <button className="copy-button">Copy</button>
                                      </CopyToClipboard>
                                    </pre>
                                    <br />
                                    <strong>Intent:</strong> {queryObj.intent}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          )}
                          {message.meta_data.modifiedSqlQueries && message.meta_data.modifiedSqlQueries.length > 0 && (
                            <div className="meta-data-item">
                              <strong>Modified Queries:</strong>
                              <ul>
                                {message.meta_data.modifiedSqlQueries.map((query, index) => (
                                  <li key={index}>
                                    <strong>Query:</strong> 
                                    <pre className="code-block">
                                    {sqlFormat(query, { language: 'postgresql' })}
                                    <CopyToClipboard text={query}>
                                        <button className="copy-button">Copy</button>
                                      </CopyToClipboard>
                                    </pre>
                                    <br />
                                  </li>
                                ))}
                              </ul>
                            </div>
                          )}
                          {message.meta_data.originalSQLResponse && (
                            <div className="meta-data-item">
                              <strong>Original SQL Response:</strong> 
                              <div
                                className="markdown-msg"
                                dangerouslySetInnerHTML={{
                                  __html: new MarkdownIt().render(message.meta_data.originalSQLResponse.content[0].text),
                                }}
                              />
                            </div>
                          )}
                          {message.meta_data.extraData && renderExtraData(message.meta_data.extraData)}
                        </div>
                      )}
                    </>
                  )}
                </>
              )}
              <div className="message-timestamp">{formattedDate}</div>
              {message && !showFinalResults && (!message.meta_data || (message.meta_data && !message.meta_data.wasCacheUsed)) && (
                <div className="feedback-section">
                  <p className="feedback-question">Was it helpful?</p>
                  <div className="feedback-buttons">
                    <button
                      className={`feedback-button ${feedback === 'yes' ? 'selected' : ''}`}
                      onClick={() => handleFeedback('yes')}
                    >
                      Yes
                    </button>
                    <button
                      className={`feedback-button ${feedback === 'no' ? 'selected' : ''}`}
                      onClick={() => handleFeedback('no')}
                    >
                      No
                    </button>
                  </div>
                </div>
              )}
            </div>
            {message && message.message && message.message.graphContent && (
              <div
                className={`bot-message-graph-insight-container${
                  darkMode ? "-dark" : ""
                }`}
              >
                <div
                  className={`bot-message-graph${
                    darkMode ? "-dark" : ""
                  } border-radius-l  p-l custom-scroll`}
                >
                  {renderedGraph
                    ? renderedGraph
                    : message.message.graphContent.graph_vals}
                </div>
                <div
                  className={`bot-message-insight${
                    darkMode ? "-dark" : ""
                  } border-radius-l  p-l`}
                >
                  {message.message.graphContent.insight}
                </div>
              </div>
            )}
            {message &&
              message.message &&
              message.message.graphContent &&
              message.message.graphContent.outro && (
                <div
                  className={`bot-message-outro${
                    darkMode ? "-dark" : ""
                  } border-radius-l  p-l`}
                >
                  {message.message.graphContent.outro}
                </div>
              )}
          </div>
        </div>
      )}
    </>
  );
}