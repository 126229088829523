import React from 'react';
import plusLight from '../../assets/chatbot/plus_light.svg';
import plusDark from '../../assets/chatbot/plus_dark.svg';
import Ripples from 'react-ripples';

export default function NewChatBtn({ loading, setSelectedThread, setChatLog, darkMode }) {
  function handleNewChat() {
    if(!loading) {
      setSelectedThread();
      setChatLog([]); 
    } 
  }

  return (
    
    <Ripples className={`new-chat-btn-ripple-wrapper`} color={`${loading ? '' : 'rgb(255,255,255,0.05)'}`} during={1000}>
      <button className={`new-chat-btn${darkMode ? '-dark' : ''} d-flex row ${loading ? 'cursor-not-allowed' : 'cursor-pointer'}`} onClick={() => handleNewChat()} >
        <img src={darkMode ? plusDark :  plusLight} alt=''/><span className='text-white fw-m ml-m'>New Thread</span>
      </button>
    </Ripples>

  )
}
