import React, { useState, useRef, useEffect, Component } from "react";

export const AnimatedText = ({ text, delay = 50 }) => {
    const [displayedText, setDisplayedText] = useState('');
  
    useEffect(() => {
      let i = 0;
      const intervalId = setInterval(() => {
        setDisplayedText(text.slice(0, i));
        i++;
        if (i > text.length) clearInterval(intervalId);
      }, delay);
  
      return () => clearInterval(intervalId);
    }, [text, delay]);
  
    return <span>{displayedText}</span>;
  };