export default function ChatSuggestions({ threads, selectedThread, suggestion, chatLog, setSuggestion, darkMode, handleSubmit }) {
  // console.log('suggestion:', chatLog[chatLog.length-1].suggestion)
  return (
    <small className={`suggestions-container${darkMode ? '-dark' : ''} w-80`}>
      {
        threads && threads[0] && threads[0][0]===selectedThread?.id && chatLog && chatLog.length>1 && chatLog[chatLog.length-1]?.sender === 'assistant' && suggestion && suggestion.options && suggestion.options.length>0 &&
        <div>
          <span>SUGGESTION: </span>
          <div className='suggestions-list'>
            {suggestion.options.map((suggestion, index) => {
            return (
              <span 
                onClick={() => {
                  setSuggestion((prevState) => ({...prevState, selected: true, value: suggestion}));
                  handleSubmit(suggestion);
                }}
                className={`suggestion-item${darkMode ? '-dark' : ''} cursor-pointer mr-m mb-m`}>
                  {suggestion}
                </span>
              )
            })}
          </div>
        </div>
      }
    </small>
  )
}
